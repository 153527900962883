import React from "react";
import styled from "styled-components";
import { useFetch, fetchData } from "lib/helpers/fetchData";

import { ListPane } from "lib/components/ListPane";
import { UpsertCustomer } from "./components/UpsertCustomer";
import { SingleCustomer } from "./components/SingleCustomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faEnvelope,
  faBiohazard,
  faUserNinja,
  faAudioDescription,
  faLockOpen,
  faBookOpen,
  faRocket,
  faFileContract
} from "@fortawesome/free-solid-svg-icons";

export const loadCustomersList = async () => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers`);
};

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: ${(props) => (props.accent || props.theme.text)};
  opacity: ${(props) => (props.active === "true" ? 0.7 : 0.1)};
`;

const getSocIconColour = (ingestionOption) => {
  switch(ingestionOption) {
    case "ecl": return "#00a1e6"
    case "integrations": return "#d40075"
  default: return ""
  }
}

export const Customers = () => {
  const state = useFetch(loadCustomersList);
  const icon = (customer = {}) => (
    <>
      {customer.auth?.sso?.enabled && <StyledIcon title="AD SSO" icon={faAudioDescription} active="true" />}
      {customer.apps?.soc?.mispTI && <StyledIcon title="MISP TI" icon={faBookOpen} active="true" />}
      {customer.apps?.soc?.resourceOrchestrator && <StyledIcon title="Resource Orchestrator" icon={faRocket} active="true" />}
      <StyledIcon
        title="Soc"
        icon={faShieldAlt}
        active={`${customer.apps?.["soc"]?.enabled}`}
        accent={getSocIconColour(customer?.apps?.soc?.ingestion)}
      />
      <StyledIcon title="Vuln" icon={faBiohazard} active={`${customer.apps?.["vuln"]?.enabled}`} />
      <StyledIcon title="Phish" icon={faEnvelope} active={`${customer.apps?.["phish"]?.enabled}`} />
      <StyledIcon title="Phishing Simulation" icon={faUserNinja} active={`${customer.apps?.["phish-sim"]?.enabled}`} />
      <StyledIcon title="Pentest" icon={faLockOpen} active={`${customer.apps?.["pentest"]?.enabled}`} />
      <StyledIcon title="MDS Service" icon={faFileContract} active={`${customer.apps?.["mds"]?.enabled}`} />

    </>
  );
  return (
    <ListPane
      sort
      hasSearch
      listRightComponent={icon}
      listState={state}
      listName="Customer"
      listWidth={400}
      upsertPane={UpsertCustomer}
      singleItemPane={SingleCustomer}
    />
  );
};
